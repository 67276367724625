.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiAppBar-root {
  background-color: rgba(57, 59, 65, 1) !important;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  font-family: Roboto;
  z-index: 1000;
}
.MuiContainer-root {
  padding-top:60px;
  padding-left:0px !important;
  padding-right:0px !important;
}

@media only screen and (min-width: 600px) {
  .MuiContainer-root {
    padding-top:70px;
    padding-left:10px !important;
    padding-right:10px !important;
  }
}

.MuiCardHeader-root {
  padding: 5px !important;
}

.MuiCardContent-root {
  padding: 5px !important;
}

.box-header {
  background-color: #393b41;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  padding: 2px;
}


.orari-messe-box {
  width: 100%;
  padding: 0px;
  margin: auto;
  background-color: #f7f7f7e1;
  border: 2px solid #282c344d;
  border-radius: 5px;
}

.css-tlelie-MuiListItemText-root  {
  margin: 0 !important;
}

.messagesBody {
  margin: 10px;
  padding: 8px;
  background-color: #eeeeee;
}

textarea {
  width: 100%;
  height: 200px;
  padding: 5px 5px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}